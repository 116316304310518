body {
    font-family: 'Montserrat', sans-serif;
    background-color: #fff;
}

.margintop-50 {
    margin-top: 50px;
}

#functioning_and_contact {
    height: 48.8px;
}

#functioning_and_contact #info {
    background-color: #233c98;
    box-shadow: 2px 4px #aa0003;
}

#functioning_and_contact nav {
    padding: 0.40rem;
    color: #fff;
    font-size: 18px;
}

#functioning_and_contact nav img {
    margin-right: 10px;
}

#main_menu .nav-link {
    color: #898ea0;
    font-size: 15px;
    margin: 0 3px;
}

#main_menu .nav-link:hover {
    border-bottom: 1px solid #14235b;
}

#main_menu .active {
    font-weight: bold;
    border-bottom: 1px solid #14235b;
}

.icons_social_media a {
    margin-right: 10px;
    text-decoration: none;
}

.margintop-150 {
    margin-top: 100px;
}

#servicos .card-body .card-text {
    font-size: 15px;
}

.container-pages .title h1,
.container-pages .title h2 {
    font-size: 45px;
    font-weight: bold;
    text-transform: uppercase;
}

.video-container {
    position: relative;
    padding-bottom: 0;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.container-pages .text-description {
    font-size: 22px;
}

.container-pages p {
    margin-bottom: 30px;
}

.container-pages p:last-child {
    margin-bottom: 0;
}

#entre_em_contato {
    max-height: 292px;
    background: url('/images/background_contact.jpg') no-repeat;
}

#entre_em_contato .title h2 {
    font-size: 50px;
    font-weight: bold;
    text-transform: uppercase;
}

#list-videos .col-lg-3 {
    padding: 0 !important;
    margin: 0 !important;
}

.info-team h2 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
}

.info-team p {
    font-size: 18px;
}

#depoimentos .container-pages {
    background: url('/images/icons/aspas.png') no-repeat right top;
}

.depositions .top-depositions {
    border-top: 25px solid transparent;
    border-left: 0 solid transparent;
    height: 0;
}

.depositions .bottom-depositions {
    border-bottom: 25px solid transparent;
    border-right: 0 solid transparent;
    height: 0;
}

.depositions .depositions-content {
    padding: 25px;
    font-size: 16px;
}

.depositions .first .depositions-content {
    background-color: #497bba;
}

.depositions .second .depositions-content {
    background-color: #ecb950;
}

.depositions .third .depositions-content {
    background-color: #c8545f;
}


.depositions .company-info-depositions {
    text-align: right;
}

.depositions .company-info-depositions p {
    margin: 0;
}

.depositions .company-info-depositions .company {
    text-transform: uppercase;
}

footer {
    background: url('/images/background_footer.jpg') no-repeat;
    padding: 200px 0;
}

footer .title h2 {
    color: #fff;
    font-size: 30px;
    font-weight: bold !important;
}

footer .title hr {
    background-color: #6c7184;
    height: 2px;
}

footer .menu .list-group-item {
    background: none;
    border: none;
}

footer .menu .list-group-item a {
    color: #fff;
    font-size: 23px;
}

footer .address {
    color: #fff;
    font-size: 16px;
}

#footer_icons_social_media a {
    margin-right: 0 !important;
}

/* ---------- MOBILE ---------- */
@media (max-width: 991px) {
    .margintopresponsive-25 {
        margin-top: 25px;
    }

    .marginbottomresponsive-25 {
        margin-bottom: 25px;
    }

    .team_photo {
        text-align: center;
    }

    footer {
        padding: 50px 0;
    }
}